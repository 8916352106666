// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-choirs-index-js": () => import("./../../../src/pages/choirs/index.js" /* webpackChunkName: "component---src-pages-choirs-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-fees-index-js": () => import("./../../../src/pages/fees/index.js" /* webpackChunkName: "component---src-pages-fees-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-members-index-js": () => import("./../../../src/pages/members/index.js" /* webpackChunkName: "component---src-pages-members-index-js" */),
  "component---src-pages-members-resources-js": () => import("./../../../src/pages/members/resources.js" /* webpackChunkName: "component---src-pages-members-resources-js" */),
  "component---src-pages-members-teach-tracks-js": () => import("./../../../src/pages/members/teach-tracks.js" /* webpackChunkName: "component---src-pages-members-teach-tracks-js" */),
  "component---src-pages-what-we-sing-index-js": () => import("./../../../src/pages/what-we-sing/index.js" /* webpackChunkName: "component---src-pages-what-we-sing-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-update-js": () => import("./../../../src/templates/update.js" /* webpackChunkName: "component---src-templates-update-js" */)
}

